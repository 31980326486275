import axios from "axios";

const prefix = "/investments";

export default {

  async newInvestment(payload) {
    let json = await axios.post(prefix, payload);
    return json;
  },

  async top() {
    let json = await axios.get(prefix + "/top");
    return json;
  },

  async pending() {
    let json = await axios.get(prefix + "/status/pending");
    return json;
  },

  async status(status) {
    let json = await axios.get(prefix + "/status/" + status);
    return json;
  },

  async source(source) {
    let json = await axios.get(prefix + "/source/" + source);
    return json;
  },

  async userProfile(uuid) {
    let json = await axios.get(prefix + "/user/" + uuid);
    return json;
  },

  // requests
  async statusUpdate(uuid, payload) {
    let json = await axios.put(prefix + "/" + uuid, payload);
    return json;
  },

  async postPayment(uuid) {
    let json = await axios.put(prefix + "/" + uuid + "/postpayment");
    return json;
  },

  async assignProject(uuid, payload) {
    let json = await axios.put(prefix + "/" + uuid + "/assignproject", payload);
    return json;
  },

  async pendingPayouts(uuid) {
    let json = await axios.get("/returns/user/" + uuid + "/pending");
    return json;
  },

  async monthlyPayout(uuid) {
    let json = await axios.get("/returns/user/" + uuid + "/monthly");
    return json;
  },

  async payoutCalc() {
    let json = await axios.put("/payoutcalc");
    return json;
  },


};
