import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import store from '@/store/index'
Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  // mode: 'history',
  routes: [
    {
      path: '/',
      component: AuthLayout,
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
    },
    {
      path: '/app',
      redirect: 'dashboard',
      component: DashboardLayout,
      // beforeEnter: authGuard,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // beforeEnter: roleGuard,
          component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue')
        },
        {
          path: '/stats',
          name: 'stats',
          // beforeEnter: roleGuard,
          component: () => import(/* webpackChunkName: "stats" */ './views/Stats.vue')
        },
        {
          path: '/projection',
          name: 'projection',
          beforeEnter: roleGuard,
          component: () => import(/* webpackChunkName: "projection" */ './views/Projection.vue')
        },
        {
          path: '/investments',
          name: 'investments',
          beforeEnter: roleGuard,
          component: () => import(/* webpackChunkName: "investments" */ './views/Investments.vue')
        },
        {
          path: '/investors',
          name: 'investors',
          component: () => import(/* webpackChunkName: "investors" */ './views/Investors.vue')
        },
        {
          path: '/top-investors',
          name: 'top-investors',
          component: () => import(/* webpackChunkName: "top-investors" */ './views/TopInvestor.vue')
        },
        {
          path: '/elgible-for-payout',
          name: 'elgible-for-payout',
          component: () => import(/* webpackChunkName: "top-investors" */ './views/EligibleForPayout.vue')
        },
        {
          path: '/payout-forecast',
          name: 'payout-forecast',
          component: () => import(/* webpackChunkName: "top-investors" */ './views/PayoutForecastAll.vue')
        },
        {
          path: '/investor/:uuid',
          name: 'investor',
          component: () => import(/* webpackChunkName: "investor" */ './views/Investor.vue')
        },
        {
          path: '/payouts',
          name: 'payouts',
          component: () => import(/* webpackChunkName: "payouts" */ './views/Payouts.vue')
        },
        {
          path: '/projects',
          name: 'projects',
          component: () => import(/* webpackChunkName: "projects" */ './views/Projects/Projects.vue')
        },
        {
          path: '/projects/:uuid',
          name: 'projectDetails',
          component: () => import(/* webpackChunkName: "projects" */ './views/Projects/ProjectDetails.vue')
        },
        {
          path: '/borrowers',
          name: 'borrowers',
          component: () => import(/* webpackChunkName: "borrowers" */ './views/Borrowers.vue')
        },
        {
          path: '/borrower-accounts',
          name: 'borrowersAccounts',
          component: () => import(/* webpackChunkName: "borrowers" */ './views/BorrowerAccounts.vue')
        },
        {
          path: '/users',
          name: 'users',

          component: () => import(/* webpackChunkName: "users" */ './views/Users.vue')
        },
        {
          path: '/pages',
          name: 'pages',
          component: () => import(/* webpackChunkName: "pages" */ './views/Pages.vue')
        },
        {
          path: '/roles',
          name: 'roles',
          component: () => import(/* webpackChunkName: "roles" */ './views/Roles.vue')
        },

        {
          path: '/icons',
          name: 'icons',
          component: () => import(/* webpackChunkName: "demo" */ './views/Icons.vue')
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/maps',
          name: 'maps',
          component: () => import(/* webpackChunkName: "demo" */ './views/Maps.vue')
        },
        {
          path: '/tables',
          name: 'tables',
          component: () => import(/* webpackChunkName: "demo" */ './views/Tables.vue')
        }
      ]
    },
    // {
    //   path: '/',
    //   redirect: 'login',
    //   component: AuthLayout,
    //   children: [
    //     {
    //       path: '/login',
    //       name: 'login',
    //       component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
    //     },
    //     {
    //       path: '/register',
    //       name: 'register',
    //       component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue')
    //     }
    //   ]
    // }
  ]
})


/* function authGuard(to, from, next) {
  if (localStorage.getItem('token')) {
    // eslint-disable-next-line
    console.log("Has auth")
    next();
  } else {
    console.log("No auth")
    next("/");
  }
} */

function roleGuard(to, from, next) {
  console.log(to);
  if (localStorage.getItem(`${tokenName}`)) {
    // if (localStorage.getItem('user')) {
    console.log(store.getters["auth/user"])
    if (store.getters["auth/user"].is_super == 1) {
      // eslint-disable-next-line
      console.log("Super user");
      next();
    }
    else {
      // eslint-disable-next-line
      console.log(to);
    }

    // }

    next();
  } else {
    alert("Not authorized")
  }
}